import React, { Component } from 'react';
import PropTypes from 'prop-types';


export class TodoItem extends Component {
	getStyle = () => {
		return {
			background: '#f4f4f4',
			padding: '10px',
			borderBottom: '1px #ccc dotted',
			textDecoration: this.props.todo.completed ? 'line-through' : 'none',
		}
	}

	render() {
		const { id, title, completed } = this.props.todo;

		return (
			<li style={this.getStyle()}>
				<input type="checkbox" onChange={this.props.markComplete.bind(this, id)} checked={completed} /> {' '}
				{title}
				<button onClick={this.props.delItem.bind(this, id)} style={btnStyle}>x</button>
			</li>
		)
	}
}

TodoItem.propTypes = {
	todo: PropTypes.object.isRequired,
	markComplete: PropTypes.func.isRequired,
	delItem: PropTypes.func.isRequired,
}

const btnStyle = {
	background: '#F00',
	color: '#FFF',
	border: 'none',
	padding: '5px 8px',
	borderRadius: '50%',
	cursor: 'pointer',
	float: 'right',
}

export default TodoItem;
