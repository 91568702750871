import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TodoItem from './TodoItem'

class Todos extends Component {
  // props = this.props;

  render() {
    return(
      <ul>
        {this.props.todos.map((todo) => {
          return (
            <TodoItem
              key={todo.id}
              todo={todo}
              markComplete={this.props.markComplete}
              delItem={this.props.delItem}
            />
          )
        })}
      </ul>
    );
  }
}

Todos.propTypes = {
  todos: PropTypes.array.isRequired,
  markComplete: PropTypes.func.isRequired,
  delItem: PropTypes.func.isRequired,
}

export default Todos;
