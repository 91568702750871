import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class AddTodo extends Component {
	state = {
		'title': ''
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value})
	}

	onSubmit = (e) => {
		e.preventDefault();

		if (this.state.title === "") return;

		this.props.addTodo(this.state.title);
		this.setState({title: ''});
	}

	render() {
		return (
			<form onSubmit={this.onSubmit} style={{ display: 'flex', marginBottom: '10px' }}>
				<input
					type="text"
					name="title"
					placeholder="Adicionar tarefa..."
					style={{ flex: 10, padding: '5px' }}
					onChange={this.onChange}
					value={this.state.title}
				/>
				<input
					type="submit"
					value="Adicionar"
					className="btn"
					style={{ flex: 1, padding: '5px 10px' }}
				/>
			</form>
		)
	}
}

AddTodo.propTypes = {
	addTodo: PropTypes.func.isRequired,
}

export default AddTodo
