import React, { Component } from 'react';

export class Footer extends Component {
	render() {
		return (
			<div style={{marginTop: '20px', textAlign: 'center'}}>
				<a href="./about">Sobre este App</a>
			</div>
		)
	}
}

export default Footer
