import React, { Component } from 'react'

export class About extends Component {
	render() {
		return (
			<div>
				<h1 style={{ marginBottom: '10px' }}>Sobre</h1>
				<p style={{ marginBottom: '5px' }}>Este app foi criado por <a href="https://gficher.com"> Gustavo Ficher</a>.</p>
				<p>O conteúdo e tutorial deste app foi disponibilizado por <a href="https://www.youtube.com/channel/UC29ju8bIPH5as8OGnQzwJyA">Traversy Media</a> no tutorial <a href="https://youtu.be/sBws8MSXN7A">React JS Crash Course - 2019</a></p>

				<a href="../">Voltar</a>
			</div>
		)
	}
}

export default About
